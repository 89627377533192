<template>
	<div class="grid lg:grid-cols-3 gap-5">
		<div class="lg:col-span-2 h-full overflow-y-auto space-y-2">
			<div class="flex space-x-2">
				<n-button v-for="(type, index) in catalogTypeOptions" :key="index" :color="currentNavType == type.value ? 'primary' : 'secondary'" @onClick="changeNavType(type.value)">{{ type.text }}</n-button>
			</div>

			<n-data-table ref="dataTable" title="目錄列表" :columns="fields" :data="currentNavType ? catalog(currentNavType) : catalogs" actions="view,edit,search,refresh" @view="getCatalog" @edit="archiveCatalog">
				<template v-slot:type="{ row }"> {{ catalogTypeName(row.type).text }}</template>

				<template v-slot:name="{ row }">
					<p>{{ row.name['tc'] }}</p>
					<ul v-if="catalogTypeName(row.type).related" class="flex flex-wrap gap-2 my-2">
						<li v-for="(catalog, index) in catalogByMerchantId(row.merchantId, catalogTypeName(row.type).related)" :key="index">
							<n-badge color="muted">{{ catalog.name['tc'] }}</n-badge>
						</li>
					</ul>
					<ul v-if="row.type === 'VARIANT'" class="flex flex-wrap gap-5 my-2">
						<li v-for="(price, text, index) in row.price" :key="index" class="flex flex-col items-center bg-light-gray p-2 rounded-lg">
							<label>{{ text }}</label>
							<span>${{ price }}</span>
						</li>
					</ul>
					<template v-if="row.type === 'DISCOUNT' && row.conditions.length > 0">
						<ul v-for="(condition, index) in row.conditions" :key="'condition' + index" class="flex flex-wrap gap-5 my-2">
							<li class="flex items-center">{{ condition['type'] }}</li>
							<li class="flex flex-col items-center bg-light-gray p-2 rounded-lg">
								<span>${{ condition['amount'] }}</span>
							</li>
							<li class="flex flex-col items-center bg-light-gray p-2 rounded-lg">
								<span>${{ condition['quantity'] }}</span>
							</li>
						</ul>
					</template>
					<template v-if="row.type === 'DISCOUNT' && row.offers.length > 0">
						<ul v-for="(offer, index) in row.offers" :key="'offer' + index" class="flex flex-wrap gap-5 my-2">
							<li class="flex items-center">{{ offer['type'] }}</li>
							<li class="flex flex-col items-center bg-light-gray p-2 rounded-lg">
								<span>${{ offer['amount'] }}</span>
							</li>
							<li class="flex flex-col items-center bg-light-gray p-2 rounded-lg">
								<span>${{ offer['percentage'] }}</span>
							</li>
						</ul>
					</template>
				</template>
				<template v-slot:merchantId="{ row }"> {{ merchantName(row.merchantId).name['tc'] }}</template>
				<template v-slot:status="{ row }">
					<n-badge :color="statusName(row.status).color"> {{ statusName(row.status).label }}</n-badge>
				</template>
			</n-data-table>
		</div>

		<div class="lg:col-span-1 space-y-2 text-xl h-full overflow-auto">
			<p class="text-4xl pb-2">{{ selectedCatalog.id ? '修改' : '新增' }}目錄</p>
			<div class="flex space-x-2 py-2">
				<label for="merchant">所屬類別</label>
				<n-select :disabled="selectedCatalog.id ? true : false" v-model="selectedCatalog.type" @input="selectedType(selectedCatalog.type)" :options="catalogTypeOptions" :reduce="option => option.value" :get-option-label="option => option.text" class="w-full h-full text-base" placeholder="請選擇類別"></n-select>
			</div>

			<div v-if="selectedCatalog.type" class="flex space-x-2 py-2">
				<label for="merchant">所屬商戶</label>
				<n-select
					v-model="selectedCatalog.merchantId"
					:options="merchants"
					:reduce="merchant => merchant.id"
					:get-option-label="merchant => merchant.name['tc']"
					@input="
						selectedCatalog.categoryIds = ''
						selectedCatalog.productIds = ''
						selectedCatalog.variantIds = ''
					"
					class="w-full h-full text-base"
					placeholder="請選擇商戶"
				></n-select>
			</div>

			<div v-if="selectedCatalog.merchantId" class="flex space-x-2 py-2">
				<label for="name">目錄名稱</label>
				<n-input id="name" type="text" placeholder="請輸入名稱" v-model="selectedCatalog.name['tc']" />
			</div>

			<div v-if="selectedCatalog.merchantId">
				<div v-if="selectedCatalog.type == 'MENU'" class="flex space-x-2">
					<label for="name">包含類別</label>
					<n-select v-model="selectedCatalog.categoryIds" multiple :options="catalogByMerchantId(selectedCatalog.merchantId, 'CATEGORY')" :reduce="option => option.id" :get-option-label="option => option.name['tc']" class="w-full h-full text-base" placeholder="請選擇類別"></n-select>
				</div>

				<div v-if="selectedCatalog.type == 'CATEGORY'" class="flex space-x-2">
					<label for="name">包含商品</label>
					<n-select v-model="selectedCatalog.productIds" multiple :options="catalogByMerchantId(selectedCatalog.merchantId, 'PRODUCT')" :reduce="option => option.id" :get-option-label="option => option.name['tc']" class="w-full h-full text-base" placeholder="請選擇商品"></n-select>
				</div>

				<div v-if="selectedCatalog.type == 'PRODUCT'" class="flex space-x-2">
					<label for="name">商品變化</label>
					<n-select v-model="selectedCatalog.variantIds" multiple :options="catalogByMerchantId(selectedCatalog.merchantId, 'VARIANT')" :reduce="option => option.id" :get-option-label="option => option.name['tc']" class="w-full h-full text-base" placeholder="請選擇變化"></n-select>
				</div>

				<div v-if="selectedCatalog.type == 'VARIANT' || selectedCatalog.price" class="flex flex-col space-y-2 my-5">
					<div class="flex space-x-2">
						<label for="DELIVERY" class="whitespace-nowrap">外賣</label>
						<n-input type="number" v-model.number="selectedCatalog.price['DELIVERY']" placeholder="請輸入外賣價錢" />
					</div>
					<div class="flex space-x-2">
						<label for="DINEIN" class="whitespace-nowrap">堂食</label>
						<n-input type="number" v-model.number="selectedCatalog.price['DINEIN']" placeholder="請輸入堂食價錢" />
					</div>
					<div class="flex space-x-2">
						<label for="PICKUP" class="whitespace-nowrap">自取</label>
						<n-input type="number" v-model.number="selectedCatalog.price['PICKUP']" placeholder="請輸入自取價錢" />
					</div>
				</div>
				<!-- 優惠內容 -->
				<div v-if="selectedCatalog.type == 'DISCOUNT'" class="flex flex-col space-y-5 my-5">
					<p>已添加優惠內容</p>
					<n-badge> 條件列表</n-badge>
					<div v-for="(condition, index) in selectedCatalog.conditions" :key="'condition' + index" class="space-y-2">
						<div class="flex space-x-2">
							<label for="conditions">條件</label>
							<n-select v-model="condition['type']" :options="conditionTypeOptions" :reduce="option => option.value" :get-option-label="option => option.text" class="w-full h-full text-base" placeholder="請選擇條件"></n-select>
						</div>
						<div class="flex space-x-2">
							<label for="amount">價錢</label>
							<n-input type="number" v-model.number="condition['amount']" placeholder="請輸入價錢" />
						</div>
						<div class="flex space-x-2">
							<label for="quantity">數量</label>
							<n-input type="number" v-model.number="condition['quantity']" placeholder="請輸入數量" />
						</div>
					</div>
					<n-badge> 優惠列表</n-badge>
					<div v-for="(offer, index) in selectedCatalog.offers" :key="'offer' + index" class="space-y-2">
						<div class="flex space-x-2">
							<label for="offers">優惠</label>
							<n-select v-model="offer['type']" :options="offerTypeOptions" :reduce="option => option.value" :get-option-label="option => option.text" class="w-full h-full text-base" placeholder="請選擇優惠"></n-select>
						</div>
						<div class="flex space-x-2">
							<label for="amount">價錢</label>
							<n-input type="number" v-model.number="offer['amount']" placeholder="請輸入價錢" />
						</div>
						<div class="flex space-x-2">
							<label for="percentage">百份比</label>
							<n-input type="number" v-model.number="offer['percentage']" placeholder="請輸入百份比" />
						</div>
					</div>

					<!-- 加入新優惠 -->
					<div v-if="selectedCatalog.merchantId" class="space-y-2 py-2">
						<p>添加優惠內容</p>
						<div class="flex space-x-2">
							<label for="conditions">優惠類別</label>
							<n-select
								v-model="selectedDiscountType"
								:options="[
									{ value: 'CONDITION', text: '條件' },
									{ value: 'OFFER', text: '優惠' },
								]"
								:reduce="option => option.value"
								:get-option-label="option => option.text"
								class="w-full h-full text-base"
								placeholder="請選擇優惠類別"
							></n-select>
						</div>

						<template v-if="selectedDiscountType === 'CONDITION'">
							<p class="text-xl">新條件</p>
							<div class="flex space-x-2">
								<label for="variant">條件</label>
								<n-select v-model="conditionItem['type']" :options="conditionTypeOptions" :reduce="option => option.value" :get-option-label="option => option.text" class="w-full h-full text-base" placeholder="請選擇條件"></n-select>
							</div>

							<div class="flex space-x-2">
								<label for="number">價錢</label>
								<input type="number" v-model.number="conditionItem['amount']" class="w-full h-full text-base border border-gray px-3" placeholder="請選擇類別" />
							</div>

							<div class="flex space-x-2">
								<label for="number">數量</label>
								<input type="number" v-model.number="conditionItem['quantity']" class="w-full h-full text-base border border-gray px-3" placeholder="請選擇價錢" />
							</div>

							<n-button @onClick="addDiscountItem(conditionItem)">加入新條件</n-button>
						</template>

						<template v-if="selectedDiscountType === 'OFFER'">
							<p class="text-xl">新優惠</p>
							<div class="flex space-x-2">
								<label for="variant">優惠</label>
								<n-select v-model="offerItem['type']" :options="offerTypeOptions" :reduce="option => option.value" :get-option-label="option => option.text" class="w-full h-full text-base" placeholder="請選擇條件"></n-select>
							</div>

							<div class="flex space-x-2">
								<label for="number">價錢</label>
								<input type="number" v-model.number="offerItem['amount']" class="w-full h-full text-base border border-gray px-3" placeholder="請選擇類別" />
							</div>

							<div class="flex space-x-2">
								<label for="number">百份比</label>
								<input type="number" v-model.number="offerItem['percentage']" class="w-full h-full text-base border border-gray px-3" placeholder="請選擇價錢" />
							</div>
							<n-button @onClick="addDiscountItem(offerItem)">加入新優惠</n-button>
						</template>
					</div>
				</div>
			</div>

			<div v-if="selectedCatalog.merchantId" class="flex space-x-2 py-2">
				<label for="status">目錄狀態</label>
				<n-select v-model="selectedCatalog.status" :options="statusOptions" :reduce="status => status.value" class="w-full h-full text-base" placeholder="請選擇狀態"></n-select>
			</div>

			<p v-if="error" class="text-danger">{{ '錯誤訊息:' + error }}</p>

			<div class="flex space-x-2 py-2">
				<n-button v-if="selectedCatalog.id" @onClick="$router.go(0)" color="danger">返回</n-button>
				<n-button @onClick="selectedCatalog.id ? updateCatalog(selectedCatalog) : createCatalog(selectedCatalog)" color="primary">{{ selectedCatalog.id ? '修改目錄' : '加入目錄' }} </n-button>
			</div>
		</div>
	</div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { catalog, discountCondition, discountOffer } from '@/model/catalog'

export default {
	data() {
		return {
			selectedDiscountType: '',
			conditionItem: discountCondition(),
			offerItem: discountOffer(),
			currentNavType: '',
			catalogTypeOptions: [
				{ value: '', text: '全部', related: null },
				{ value: 'MENU', text: '菜單', related: 'CATEGORY' },
				{ value: 'CATEGORY', text: '類別', related: 'PRODUCT' },
				{ value: 'PRODUCT', text: '商品', related: 'VARIANT' },
				{ value: 'VARIANT', text: '變化', related: null },
				{ value: 'DISCOUNT', text: '優惠', related: null },
			],
			conditionTypeOptions: [
				{ value: 'ORDER_AMOUNT', text: '價錢' },
				{ value: 'ORDER_QUANTITY', text: '數量' },
			],
			offerTypeOptions: [
				{ value: 'ORDER_AMOUNT', text: '價錢' },
				{ value: 'ORDER_PERCENTAGE', text: '百份比' },
			],
			selectedCatalog: catalog(),
			fields: [
				{
					label: '類別',
					prop: 'type',
					sortable: true,
				},
				{
					label: '名稱',
					prop: 'name',
					sortable: true,
				},
				{
					label: '所屬商戶',
					prop: 'merchantId',
					sortable: true,
				},
				{
					label: '狀態',
					prop: 'status',
					sortable: true,
				},
			],
			error: '',
		}
	},
	async created() {
		try {
			this.currentNavType = localStorage.getItem('type')
		} catch (error) {
			console.log(error)
			this.error = error.message
		}
	},

	mounted() {
		this.$refs.dataTable.refresh()
	},
	computed: {
		...mapState(['statusOptions']),
		...mapGetters([
			// merchant
			'merchants',
			'merchantName',
			// catalog
			'catalogs',
			'catalog',
			'currentCatalog',
			'catalogItem',
			'catalogByMerchantId',
			// state
			'statusName',
		]),
	},
	methods: {
		async addDiscountItem(discountItem) {
			if (!discountItem.type) return
			if (this.selectedDiscountType === 'OFFER') await this.selectedCatalog.offers.push(discountItem)
			if (this.selectedDiscountType === 'CONDITION') await this.selectedCatalog.conditions.push(discountItem)
			this.conditionItem = await discountCondition()
			this.offerItem = await discountOffer()
		},

		catalogTypeName(value) {
			return this.catalogTypeOptions.find(type => type.value === value)
		},
		async changeNavType(type) {
			this.currentNavType = type
			localStorage.setItem('type', type)
			this.$router.go(0)
		},
		async selectedType(type) {
			return (this.selectedCatalog = await catalog(type))
		},
		async createCatalog(data) {
			try {
				data.categoryIds ? data.categoryIds : (data.categoryIds = [])
				data.productIds ? data.productIds : (data.productIds = [])
				data.variantIds ? data.variantIds : (data.variantIds = [])
				await this.$store.dispatch('createCatalog', { catalogObject: data })
				this.$router.go(0)
			} catch (error) {
				console.log(error)
				this.error = error.message
			}
		},
		async getCatalog(row) {
			try {
				const id = row.id
				this.error = ''
				await this.$store.dispatch('getCatalog', { id })
				this.selectedCatalog = this.currentCatalog
			} catch (error) {
				console.log(error)
				this.error = error.message
			}
		},
		async archiveCatalog(row) {
			try {
				const id = row.id
				this.error = ''
				await this.$store.dispatch('archiveCatalog', { id })
				this.$router.go(0)
			} catch (error) {
				console.log(error)
				this.error = error.message
			}
		},
		async updateCatalog(data) {
			try {
				this.error = ''
				await this.$store.dispatch('updateCatalog', { id: data.id, catalogObject: data })
				this.$router.go(0)
			} catch (error) {
				console.log(error)
				this.error = error.message
			}
		},
	},
}
</script>
