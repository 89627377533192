import { locale } from './locale'

export function base() {
	return {
		merchantId: '',
		name: locale(),
		status: '',
		categoryIds: [],
		productIds: [],
		variantIds: [],
	}
}

export function catalog(type) {
	switch (type) {
		case 'VARIANT':
			return {
				type: type,
				...base(),
				...catalogPrice(),
			}
		case 'DISCOUNT':
			return {
				type: type,
				...base(),
				...catalogDiscount(),
			}
		default:
			return {
				type: type,
				...base(),
			}
	}
}

export function catalogPrice() {
	return {
		price: {
			DELIVERY: 0,
			PICKUP: 0,
			DINEIN: 0,
		},
	}
}

export function catalogDiscount() {
	return {
		conditions: [],
		offers: [],
	}
}

export function discountCondition() {
	return {
		type: '',
		amount: 0,
		quantity: 0,
	}
}
export function discountOffer() {
	return {
		type: '',
		amount: 0,
		percentage: 0,
	}
}
